import React from 'react';
import './Nav.css';
import {Link} from  'react-router-dom';
import logoo from './Image/Lrohit (1).png';
import Head from "./Header"
import { useState } from "react";





function Navi() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };

    const resetNavbar = () => {
      setActive('nav__menu');
      setIcon('nav__toggler');
   
  };
  return (<div className='nddd'>
<Head/>
    <div className="nasv">

      
    <nav className="nav">
     
     <div className='logo2'>
      <img src={logoo} className='img1'></img>
     </div>     



<div>

<br></br>
<div className='nd'>

<div className={active}>
      <ul className='ul'>
      <li className="list"><Link to='/' className="list" onClick={resetNavbar}>HOME</Link></li>
      <li className="list"><Link to='/AboutuS' className="list" onClick={resetNavbar}>ABOUT</Link></li>
      <li>
         <div class="dropdown">
           <li className="list"><Link className="list" onClick={resetNavbar}>SERVICES</Link></li>
              <div className="dropdown-content">
          
               <li className="list1"> <Link to='/' onClick={resetNavbar}> LIQUAR BOTTLE LABEL</Link></li>  
               <li className="list1">  <Link to='/' onClick={resetNavbar}>FOOD PRODUCT LABEL</Link></li>  
               <li className="list1"> <Link to='/' onClick={resetNavbar}>PHARMA LABEL</Link></li>  
               <li className="list1">  <Link to='/' onClick={resetNavbar}>COSMETIC LABEL</Link></li>  
               <li className="list1"> <Link to='/' onClick={resetNavbar}>HOUSEHOLD CLEANER LABEL</Link></li>  
               <li className="list1"> <Link to='/' onClick={resetNavbar}>INDUSTRIAL LABEL</Link></li>  
               <li className="list1"> <Link to='/' onClick={resetNavbar}>SHIPPING LABEL</Link></li>  

                   </div>
              </div>

         </li>


        <li className="list"><Link to='/OurClients' className="list" onClick={resetNavbar}>CLIENTS</Link></li>
        <li className="list"><Link to='/Gallery' className="list" onClick={resetNavbar}>GALLERY</Link></li>
        <li className="list"><Link to='/Enquiry' className="list" onClick={resetNavbar}>ENQUIRY</Link></li>
        <li className="list"><Link to='/Contact' className="list" onClick={resetNavbar}>CONTACT</Link></li>

      </ul>
      </div>
      </div>
      </div>

  


      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
    </div>
    </div>
  );
}

export default Navi;









/*
function Nav() {
  return (
    <div>


          <div className={classes.container}>
          <div className={classes.logo}>
                           <img src={logoo} className={classes.img1}></img>
          </div>
      <center> 
        <div >
        <Head />

         <div className={classes.blinklist}>
                  
      
                    <ul className={classes.blinklist}>
                      <li><Link to='/' className={classes.lit}>HOME</Link></li>
                        <li><Link to='/Aboutus' className={classes.li}>ABOUT</Link></li>
                       <div className={classes.dropdown}>
                            <Link>PRODUCTS </Link>
                            <div className={classes.dropdownContent}>
                                                  <a> LIQUAR BOTTLE LABEL</a>
                                                  <a>FOOD PRODUCT LABEL</a>
                                                  <a>PHARMA LABEL</a>
                                                  <a>COSMETIC LABEL</a>
                                                  <a>HOUSEHOLD CLEANER LABEL</a>
                                                  <a>INDUSTRIAL LABEL</a>
                                                  <a>SHIPPING LABEL</a>
                                            
                                                   </div>
                                             </div>
                      

                        <li><Link to='/OurClients' className={classes.li}>CLIENTS</Link></li> 
                        <li><Link to='/Gallery' className={classes.li}>GALLERY</Link></li>
                        <li><Link to='/Enquiry' className={classes.li}>ENQUIRY</Link></li> 
                        <li><Link to='/Contact' className={classes.li}>CONTACT</Link></li>
                        
                    </ul></div>
                    </div>

                    </center>               

</div>
                    </div>
  )
}


export default Nav

*/