import React from "react";
import Slider from "react-slick";
import Rcard from "./Rcard";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import classes from "./Rsli.module.css";
import girl from './Pic/girl.png';
import man from './Pic/man.png';

const Rsli = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 8000,
        autoplay: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 769,
              settings: {
                speed: 1000,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: false  
              }
            }
        ]
    };

    return (
      
<div className={classes.rsl}>
    
                <Slider {...settings}>
                <div>
                            <Rcard imgg={girl} name='Pramod Dongre' txt='Rohit Arts has been a long-standing supplier to our business and offers us good quality print and good lead times. The staff are very helpful and we get good customer service. 
                                                                           We look forward to continuing our supply relationship. .'
                                rate='4.4' date='June 02 2019'></Rcard>
                        </div>
                        <div>
                            <Rcard imgg={girl} name='ZAINAB SHAIKH'
                                txt='We just wanted to say a big thank you. We are exceptionally happy with the labels!! The quality is great,
                                     we love the textured paper, the colours came out beautifully and overall we are thrilled.  '
                                rate='4.0' date='Sept 09 2020'></Rcard>
                        </div>
                        <div>
                            <Rcard imgg={girl} name='Anamika Singh'
                                txt='We love the personal attention they give, as well as the great service and delivery.'
                                rate='4.3' date='July 11 2019'></Rcard>
                        </div>
                        <div>
                            <Rcard imgg={man} name='Mukesh Singh'
                                txt='The team at Rohit Art understood our needs and presented a range of options that met our requirements. Patrick was especially helpful in the printing of our earth-friendly, highly technical label. 
                                     I can’t speak highly enough of the personability and professionalism of Rohit Art!'
                                rate='3.9'  date='Augest 08 2019'></Rcard>
                        </div>
                        <div>
                            <Rcard imgg={man} name='Ajinkya Kawade'
                                txt=' Highly impressed with the quality of work they produced for us and the range of techniques and technologies
                                      that combined to deliver beautiful labels that both us and our customers absolutely loved.'
                                rate='4.6'  date='Sept 01 2019'></Rcard>
                        </div>
                        <div>
                            <Rcard imgg={man} name='ASHOK KAMBLE'
                                txt=' we have not been disappointed in our decision to work with them.Awesome Labels .'
                                rate='5.0'  date='Oct 11 2020'></Rcard>
                        </div>

                       

             </Slider>
       </div>     
       
    );
}

export default Rsli;
