import React from 'react';
import { useEffect } from 'react';
import Ab1 from './../Component/AboutUS/Ab1';
import Art from './../Component/AboutUS/Art';
import Well from './../Component/AboutUS/Well';
import Team from './../Component/AboutUS/Team';
import Vis from './../Component/AboutUS/Vis';
function AboutUS() {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (

    <div>
        <Art></Art>
     <Ab1></Ab1>
     <Well></Well>
     <Team></Team>
     <Vis></Vis>
     
    </div>
  )
}

export default AboutUS
