import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card from "./Carprop";
import img1 from './Pic/pexels-mathias-reding-10011983.jpg';
import img2 from "./Pic/slide4.jpg";
import img3 from "./Pic/slide 2.jpeg"; 
import img4 from "./Pic/slide3.jpg";
import img5 from "./../Home/Pic/slide5.jpg";


export default class Carousel extends Component {
    render() {
      const settings = {
  
        dots: false,
        fade: true,
        infinite: true,
        speed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,    
    };
      return (
        <div>
        
          <Slider {...settings}>
           
            <div>
            <Card img={img1}></Card>
            </div>

            <div>
            <Card img={img2}></Card>
            </div>

            <div>
            <Card img={img3}></Card>
            </div>

            <div>
            <Card img={img4}></Card>
            </div>
            <div>
            <Card img={img5}></Card>
            </div>


          </Slider>
        </div>
      );
    }
  }