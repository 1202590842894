import React from 'react'
import Carousel from './../Component/Home/Carousel';
import { useEffect } from 'react';
import Home1 from './../Component/Home/Home1';
import BlockCard from './../Component/Home/BlockCard';
import Rsli from './../Component/Home/Rsli';
import RockCard from './../Component/Home/RockCard';
import Expo from './../Component/Home/Expo';
import Expertise from './../Component/Home/Expertise';


function Home() {

useEffect(()=>{
  window.scrollTo(0,0)
},[])

  return (
    <div>
      <Carousel></Carousel>
      <Home1></Home1>
      <Expertise></Expertise>
      <RockCard></RockCard>
      <Expo></Expo>
      <BlockCard></BlockCard>
      <Rsli></Rsli>
     
      
    </div>
  )
}

export default Home

