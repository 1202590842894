import React from 'react';
import classes from './Galler.module.css';
import i1 from './PIck/1.jpg';
import i2 from './PIck/15190241171334559555.jpg';
import i3 from './PIck/15190244901334501145.jpg';
import i4 from './PIck/15190246021334501147.jpg';
import i5 from './PIck/15190246751334501146.jpg';
import i6 from './PIck/1519025167140910174449-70.jpg';
import i7 from './PIck/1519025386DFLT-62.jpg';
import i8 from './PIck/DirectThermalBarcodeLabels.jpg';
import i9 from './PIck/Food-bottle-labels.jpg';
import i10 from './PIck/Liquor Bottle Label.jpg';
import i11 from './PIck/Shipping label.jpg';
import i12 from './PIck/cleaning product labels.jpg';
import i13 from './PIck/cosmetic-bottle-label.jpg';
import i14 from './PIck/digital-printing-1.jpeg';
import i15 from './PIck/industrial-label.jpg';
import i16 from './PIck/laminated-labels.jpg';
import i17 from './PIck/pharma.jpg';
import i18 from './PIck/Shipping label.jpg';



const Galler = () => {
  return (
    <div className={classes.qa}>
<div className={classes.aq}>  

<center>
    <br/> 
    <p className={classes.head}>OUR PROJECT</p>
       <hr color='red' width='71rem' className={classes.headkk}></hr>
            <p className={classes.head2}>We maintain a level of transparency in all our dealing, clients can opt for payments option in cash, cheque, or DD, which has further, made us the reputed enterprise in the domain.
            In order to store products in well- coordinated manner, we have a team of skill storekeepers, which helps us in storing these products in unharmed manner. Leading with the team of adroit workers, we have developed 
a state-of-the-art manufacturing unit that is spread over a wide area</p>
         

<br/>


<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i1}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    
    <img className={classes.mg}  src={i2}></img>
    <p className={classes.pp}></p>

</div>

<div className={classes.col}>
    
    <img className={classes.mg} src={i3}></img>
    <p className={classes.pp}></p>

</div>

    </div>

<br/>
<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i4}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    
    <img className={classes.mg}  src={i5}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    

    
    <img className={classes.mg} src={i6}></img>
    <p className={classes.pp}></p>

</div> 
</div>
<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i7}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    
    <img className={classes.mg}  src={i8}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    

    
    <img className={classes.mg} src={i9}></img>
    <p className={classes.pp}></p>

</div> 
</div>
<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i10}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    
    <img className={classes.mg}  src={i11}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    

    
    <img className={classes.mg} src={i12}></img>
    <p className={classes.pp}></p>

</div> 
</div>
<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i13}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    
    <img className={classes.mg}  src={i14}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    

    
    <img className={classes.mg} src={i15}></img>
    <p className={classes.pp}></p>

</div> 
</div>
<div className={classes.row}>

<div className={classes.col}>
    
    <img className={classes.mg} src={i16}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    
    <img className={classes.mg}  src={i17}></img>
    <p className={classes.pp}></p>

</div>
<div className={classes.col}>
    

    
    <img className={classes.mg} src={i18}></img>
    <p className={classes.pp}></p>

</div> 
</div>

</center>
</div>



    </div>
  )
}

export default Galler