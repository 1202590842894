import React from 'react';
import Cont from '../Component/Contact/Cont';

function Contact() {
  return (
    <div>
      <Cont></Cont>
    </div>
  )
}

export default Contact
