import classes from "./Header.module.css";
import image from "./Image/email (1).png";
import image2 from "./Image/phone.png";
function Header (){
    return(
        <div className={classes.overall}>

<div className={classes.main}>
        <div className={classes.email} ><img src={image} className={classes.img} /><a href="mailto:info@rohitarts.com" className={classes.tdecor}><i className="fa-solid fa-envelope"></i> info@rohitarts.com</a></div>
        <div className={classes.mobile}><img src={image2} className={classes.img} /><a href="tel:9028079000" className={classes.tdecor}><i className="fa-solid fa-phone"></i> Mr.Rajesh Tiwari : +919028079000</a></div>
        
</div>

</div>
    );
};
export default Header;