import React from 'react'

import Nav1 from './../Component/Nav/Nav';
import classes from "./../Component/Nav/Header.module.css"

const Navi = () => {
  return (
    <div className={classes.navbg}>
    
      <Nav1></Nav1>
    </div>
  )
}

export default Navi
