import classes from './Vis.module.css';
import Vision from './PIx/vision.png';
import Mission from './PIx/mission.png';

const Vis = () => {
    return (<div className={classes.contain}>

        <p className={classes.head}>WE STRIVE FOR PERFECTION !</p>
        <hr color='red' width='71rem' className={classes.line}></hr>
        <div className={classes.hold}>

            <div className={classes.card}>
                <p className={classes.cardHead}><img src={Vision} className={classes.icon}></img> OUR VISSION</p>
                <p className={classes.cardtxt}>Delivering happiness to customers, significant cost savings and long term support.</p>
            </div>

            <div className={classes.card}>
                <p className={classes.cardHead}><img src={Mission} className={classes.icon}></img> OUR MISSION</p>
                <p className={classes.cardtxt}>To provide the highest level of service, the broadest selection of products and the most competitive prices</p>
            </div>
</div>

    </div>
    )
}

export default Vis;