import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import classes from './Cont.module.css';
import contact from '../Contact/tic/backg.jpg';
import visit from '../../Component/Contact/tic/location.png';
import phone from '../../Component/Contact/tic/call.png';
import mail from '../../Component/Contact/tic/chat.png';



const Cont = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1n792tk', 'template_e2jr2up', form.current, 'xSB01PC6tLOxFYP8j')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div className={classes.Cmain}> 
      
      <div className={classes.top}>
        <div>
        <img src={contact} className={classes.image}></img>
        </div>
        <div className={classes.flex}>
        <div className={classes.para1}>
          <h1>Contact Us</h1>
          <p>Need an Expert?<br/>
          You are more than welcome to leave your <br></br>contact info and we will be in touch shortly</p>
          </div>
          <div className={classes.left}>
          <form ref={form} onSubmit={sendEmail} className={classes.bg}>
              <label> First Name</label><br></br>
              <input type='text' name="name" className={classes.bg1}/><br></br><br></br>
              <label> Last Name</label><br></br>
              <input type='text' name="Lname" className={classes.bg1}></input><br></br><br></br>
              <label> Email address</label><br></br>
              <input type='text'name="email" className={classes.bg1}></input><br></br><br></br>
              <label>Comments</label><br></br>
              <textarea cols='45' rows='10' name="message" className={classes.bg1}>

              </textarea><br></br>
              <div>
                <button type="submit" value="Send" className={classes.btn}>SEND</button>
              </div>


            </form>
          </div>
         </div>
      </div>
      <div className={classes.add}>
        <div className={classes.first}>
          <div className={classes.A1}>
          <img src={visit} className={classes.vicon}></img>

          <h3 className={classes.h}>Visit Us</h3>
          <p className={classes.visit}>Bldg no 5, gala no 4, parel shiv smriti CHS, gandhi nagar,<br></br>  worli, Mumbai 400018, <br></br> Maharashtra, India.</p>
          </div>
        </div>
        <div className={classes.second}>
          <div className={classes.A2}>
          <img src={phone} className={classes.picon}></img>
          <h3 className={classes.hs}>Call Us</h3>
          <p><a href='tel:9773090585'>+919773090585</a></p>
      
     
          </div>

        </div>


        <div className={classes.third}>
          <div className={classes.A3}>
          <img src={mail}className={ classes.micon}></img>
          <h3 className={classes.hss}>Message Us</h3>
          <p><a href='mailto:rohitarts1@gmail.com '>rohitarts1@gmail.com </a></p>
          
          </div>

         
        </div>
      </div>

      <br></br>
   
    </div>
  )
  
}

export default Cont;