import React from 'react';
import { Outlet } from "react-router-dom";
import Nav from "./../Pages/Nav";
import Foot from './../Component/Foot/Footer';
import Wp from "./../Pages/Whatsapp"



function RootLayout() {
  return (
    <div>
    <Nav />
      <Outlet></Outlet>
      <Wp></Wp>

      <Foot></Foot>
    
     
      
    </div>
  )
}

export default RootLayout
