import React, { Component } from "react";
import classes from './BlockCard.module.css';
import Block from "./Block";
import  {Link}  from 'react-router-dom';
import img1 from './../Home/Pic/q.png';
import img2 from './../Home/Pic/D.png';
import img3 from './../Home/Pic/R.png';
import img4 from './../Home/Pic/bp.png';
import img5 from './../Home/Pic/T.png';




import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const BlockCard=()=>{
return (
    <div className={classes.hold}>
  
        <h3 className={classes.head}>Rohit Arts - A Quick Glimpse</h3>
        <Fade left>
            <div className={classes.row}>
          
            <div>
             <Block img={img1}  head='QUALITY PRODUCTS'  ></Block> 
            </div>
            <div>
              <Block img={img2}  head='DOORSTEP DELIVERY' ></Block>
            </div>
            <div>
                <Block img={img3} head='RESPONSE TIME' ></Block>
            </div>
            <div>
            <Block img={img4} head='BEST PRICE' ></Block>
            </div>
            <div>
            <Block img={img5} head='TOP BRANDS' ></Block>
            </div>
      
              
            </div>
            </Fade>        
    </div>
)
};

export default BlockCard;