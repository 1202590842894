import React, { Component } from "react";
import classes from './RockCard.module.css';
import Rock from "./Rock";
import  {Link}  from 'react-router-dom';
import img1 from './../Home/Pic/healthcare.png';
import img2 from './../Home/Pic/pets.png';
import img3 from './../Home/Pic/homecare.png';
import img4 from './../Home/Pic/electric.png';
import img5 from './../Home/Pic/food.png';
import img6 from './Pic/gadgets.png';
import img7 from './../Home/Pic/pharmaceutical.png';
import img8 from './../Home/Pic/chemical.png';




import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

const RockCard=()=>{
return (
    <div className={classes.hold}>
  
        <h3 className={classes.head}>Industry We Serve</h3>
      <p className={classes.head2}>  We deal in packaging for each industry out there.</p>
      <p className={classes.head2}>Our huge cluster of packaging is for you to browse and add a charming completion to your products.
                                     Our offerings include-</p>
        <Fade left>
            <div className={classes.row}>
          
            <div>
             <Rock img={img1}  head='Health & Personal Care'  ></Rock> 
            </div>
            <div>
              <Rock img={img2}  head='Pet Care' ></Rock>
            </div>
            <div>
                <Rock img={img3} head='Home Care' ></Rock>
            </div>
            <div>
            <Rock img={img4} head='Automobiles & Industrial' ></Rock>
            </div>
            </div>
            </Fade> 
            <Fade right>
            <div className={classes.row}>

            <div>
            <Rock img={img5} head='Food & Beverages'  ></Rock>
            </div>
            <div>
                 <Rock img={img6} head='Electronics'  ></Rock>
            </div>
            <div>
            <Rock img={img7} head='Pharmaceuticals' ></Rock>
            
            </div>
            <div>
             <Rock img={img8} head='Agriculture'></Rock>
             
            </div>
            
 
            </div>
            </Fade>
                 
    </div>
)
};

export default RockCard;