import React from 'react';
import OurClient from '../Component/Clients/OurClient';
import { useEffect } from 'react';


function OurClients() {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <div>
      <OurClient></OurClient>
     
    </div>
  )
}

export default OurClients