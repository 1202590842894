import classes from './Expertise.module.css';
import Card from './Card';
import Shed from './../Home/Pic/roll.jpg';
import Industrial from './../Home/Pic/strip-gumming.jpg';
import Machine from './../Home/Pic/monocart.jpg';
import Treemax from './../Home/Pic/popp.webp';
import Road from './../Home/Pic/Diary.jpg';
import Turnkey from './../Home/Pic/calender.png';
import Interior from './../Home/Pic/book.jpg';
import Warehouse from './../Home/Pic/Brand label.jpg';
import Fabrication from './../Home/Pic/PVC.jpg';
import Epoxy from './../Home/Pic/Adv.jpg';
import {Link} from  'react-router-dom';


const Expertise = () => {
    return (
        <div className={classes.hold}>
            <p className={classes.head}>What We Offer</p>
            <p className={classes.head2}>We stock a large range of popular labels and ribbons which are all available for next day delivery, but the majority of our work is based on customer-specific requirements, which means that the majority of the labels we ship are bespoke and manufactured to order. Our offerings include-</p>
            <hr color='red' width='71rem'></hr>

            <div className={classes.cardhold}>
          <Card image={Shed} txt='Roll Form'></Card>
          <Card image={Industrial } txt='Strip Gumming'></Card>
          <Card image={Machine} txt='Mono Carton'></Card>
           <Card image={Treemax} txt='POP Materials'></Card>

            <Card image={Road} txt='Diary'></Card>
            <Card image={Turnkey} txt='Calenders'></Card>
           <Card image={Interior} txt='Books'></Card>
           <Card image={Warehouse} txt='Brand Labels'></Card>
  <Card image={Fabrication} txt='PVC Labels '></Card>
            <Card image={Epoxy} txt='Advertising Labels'></Card>
                
            </div>
        </div>
    )
}

export default Expertise;