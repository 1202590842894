import classes from '../../Component/AboutUS/Art.module.css';


const Art=()=>{
    return <section className={classes.main}> 
            
             
               
                <h1>ABOUT US</h1>
              
             

    </section>
};


export default Art;