import React from 'react';
import Galler from './../Component/Gallery/Galler';
import Port from './../Component/Gallery/Port';
import { useEffect } from 'react';

const Gallery = () => {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <div>
    <Port></Port>
    <Galler></Galler>
    </div>
  )
}

export default Gallery