import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'


import logoo from './../Component/Nav/Image/Lrohit (1).png';

export default function Whatspp() {
    return (
        <div className="App">
            <FloatingWhatsApp
                phoneNumber="9773090585"
                accountName="Rohit Arts"
                avatar={logoo}
                chatMessage="Hello"
                allowEsc
                allowClickAway
                notification
                notificationSound
            />
        </div>
    );
}