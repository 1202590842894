import classes from './Footer.module.css';
import logo from './../Nav/Image/Lrohit (1).png';
import mail from './Sign/gmail.png';
import location from './Sign/placeholder.png';
import cell from './Sign/mobile-app.png';
import heart from './Sign/ezgif-4-5ee8b253f9-unscreen.gif';
import { Link } from 'react-router-dom';


const Footer = () => {
    return <div className={classes.container}>
        <div className={classes.hold}>

            <div className={classes.left}>
                <div className={classes.leftImgHold}>
                    <img src={logo} className={classes.leftImg}></img></div>
                <div className={classes.leftTxtHold}><span className={classes.leftTxt}>Rohit Arts is a main maker of Item Marks, Container Names, Standardized tag Marks as well as PVC/PP 
                                                      Names - for a wide range of uses of naming to fulfill the necessities of drug, compound, toiletries and beauty care products, food and refreshments, piece of clothing as well as vehicle businesses.</span></div></div>

       

            <div className={classes.middle}>
                <div className={classes.middleheadHold}>
                    <span className={classes.middlehead}>QUICK LINKS</span>
                    <hr className={classes.line}></hr>
                </div>
                <div className={classes.middlelistHold}>
                    <ul className={classes.middlelist}>
                    <li><Link to='/' className={classes.li}>HOME</Link></li>
                        <li><Link to='/Aboutus' className={classes.li}>ABOUT</Link></li>
                        <li><Link to='/OurClients' className={classes.li}>CLIENT</Link></li>
                        <li><Link to='/services' className={classes.li}>SERVICES</Link></li>
                        <li><Link to='/contact' className={classes.li}>CONTACT US</Link></li>
                    </ul>
                </div>
            </div>
            <div className={classes.right}>
                <div className={classes.rightheadHold}>
                    <span className={classes.righthead}>CONTACT DETAILS</span>
                    <hr className={classes.line}></hr>
                </div>

                <div className={classes.bilt}>
                    <img src={location} className={classes.rightlogo}></img>
                    <span>Bldg no 5, gala no 4, 
                    parel shiv smriti CHS, gandhi nagar, worli, Mumbai 400018 , 
                    Maharashtra, India.
</span>
                </div>
<br></br>
                <div>
                    <img src={mail} className={classes.rightlogo}></img>
                    <span><a className={classes.ab} href="mailto:rohitarts1@gmail.com">rohitarts1@gmail.com</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>

                <div>
                    <img src={cell} className={classes.rightlogo}></img>
                    <span> <a className={classes.ab} href="tel:9773090585">+91&nbsp;9773090585</a></span>
                </div>
            </div>

        </div>
        <hr></hr>

<hr></hr>
        <div className={classes.copyHold}>
            <span className={classes.copy}>Copyright &#169; 2023 Rohit Arts| Made with
                <img src={heart} className={classes.img}></img></span>
        </div>

    </div>
};

export default Footer;