import React from 'react';
import Enq from '../Component/Enquiry/Enq';

function Enquiry() {
  return (
    <div>
      <Enq></Enq>
    </div>
  )
}

export default Enquiry
