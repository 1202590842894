import React from 'react';
import classes from './Expo.module.css';

const Expo = () => {
  return (<div className={classes.qa}>
        <center>
<div className={classes.aq}>  

<center>
    
<h3 className={classes.head}>EXPERIENCE <span className={classes.heads}>COUNTS!!!</span></h3>

<p className={classes.p}>Our products undergo strict quality control management to ensure foolproof packaging for our clients. We do not compromise on quality at all. Therefore, we are your one-stop destination. packaging at reasonable prices,like-
<ul className={classes.ddd}>

<li className={classes.dd}> Strongly Believing in the
                           'No Compromise in Quality' Policy</li>
<li className={classes.dd}> Mission</li>
<li className={classes.dd}> Vision</li>
<li className={classes.dd}> Values</li>


</ul>
</p>
</center>

</div>
</center>



    </div>
  )
}

export default Expo