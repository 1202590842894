import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import RootLayout from './Pages/RootLayout';
import Home from './Pages/Home';
import AboutUS from './Pages/AboutUS';
import Gallery from './Pages/Gallery';
import OurClients from './Pages/OurClients';
import Enquiry from './Pages/Enquirys';
import Contact from './Pages/Contact';



const router = createBrowserRouter([{
  path: '/',
  element: <RootLayout></RootLayout>,

  children: [
    { path: '', element: <Home></Home> },
    { path: '/AboutUS', element: <AboutUS></AboutUS> },
    {path: '/Gallery', element: <Gallery></Gallery> },
    {path: '/OurClients', element: <OurClients></OurClients> },
    {path: '/Enquiry', element:<Enquiry></Enquiry>},
    {path: '/Contact',element:<Contact></Contact>},
   
    
   

  ]
}]);


const App = () => {
  return <RouterProvider router={router}></RouterProvider>

};

export default App;