import classes from './Home1.module.css';
import Rohit from './../Home/Pic/2.jpg';
import Fade from 'react-reveal/Fade';


const Home1 = () => {
    return <div className={classes.hold}>

        <div className={classes.contentHold}>
            <Fade left>
                <div className={classes.txtHold}>
                    <div className={classes.headhold}>
                        <span className={classes.head}>Welcome To Rohit Arts - Best Labels And Strips Producers And Providers In India</span>
                    </div>
                    <span className={classes.txt}>Since The Year 2000, Rohit Arts Is One Of The Highest And Perceived Manufacturers And Suppliers Of Industrial Labels And Ribbon In India.
                     Being A Rumored Industry, We Have Had the option To Raise Our Striking Situation Among The Clients.
                     Furnished With The Cutting edge Apparatuses, Hardware, And Innovation,We Production And Supply Premium Quality Names That Address Custom Issues Of The Clients. 
                     All The Most common way of Assembling Executes In Safe, Cleanliness And Clean Climate That Guarantees Predominant Attachment Property.</span>
                </div>
            </Fade>

            <Fade right>
                <div className={classes.imgHold}>
                    <img src={Rohit} className={classes.BalaImg}></img>
                </div>
            </Fade>


        </div>
    </div>
};

export default Home1;