import React from 'react';
import classes from './Ab1.module.css';

import Fade from 'react-reveal/Fade';


import img from "./../Home/Pic/wallpaper.jpg";

const Ab1 = () => {
  return (
    <div>

<section className={classes.container}>
        <div className={classes.left}>
          <br/>
<Fade top>
          <h2 className={classes.heading}>INTROD<span className={classes.heads}>UCTION</span></h2> </Fade>
          <Fade left>
            <p className={classes.leftp}>&emsp;&emsp;&emsp; "Our Company Is Working Effectively Starting around 2007 In The Field Of Industry ".

The Organizers Behind This Organization Have Individual Involvement with Assembling Of, Modern Names Starting around 2007. Before all else We Began Our Company At Limited scope and Named It "Rohit Arts". We Laid out This Company In 2007.

Brand In  Manufacturing Of Industrial Labels & Ribbons And Specialist Provider. We Look Forward Towards Incorporation With Programming Arrangements. Modern Printers, Modern Scanners, Hand-Held Terminals (Group And RF), Industrial Labels And Ribbons Is A Key Feature Of Our Services. End To End Solutions Are Further Enhanced By Our Label Division With Its State-Of-The-Art Equipments To Manufacture Labels That Meet The Custom Requirements Of Our Clients.

Our Start to finish Bar Coding Arrangements Are Worked Around The Items From Innovation Pioneers. We Are The Approved Merchant And Approved Specialist co-op For Their Whole Scope Of Items And Arrangements.</p> </Fade>
        </div>
        <div className={classes.right}><Fade right>
      <img src={img} alt="BALAJEE" className={classes.ri}/> </Fade>
        </div>
    </section>

    </div>
  )
}

export default Ab1