import classes from './Port.module.css';


const Port=()=>{
    return <section className={classes.main}> 
            
             
               
                <h1>GALLERY</h1>
              
             

    </section>
};


export default Port;