import React from 'react';
import classes from './OurClient.module.css';
import Zoom from 'react-reveal/Zoom';

const OurClient = () => {
  return (
    <div className={classes.hold}>
<br/>
<Zoom> <h3 className={classes.head}>OUR <span className={classes.heads}>CLIENTS</span></h3>  </Zoom>


<div className={classes.box}>

<div className={classes.row}>
  
<div className={classes.dow}>
<ul className={classes.ddd}>
  
<li className={classes.dd}> SML Ltd.</li>
<li className={classes.dd}> Agro Tech Foods Ltd (Sundrop )</li>
<li className={classes.dd}>Sumil Chemicals Ltd.</li>
<li className={classes.dd}> Godrej Industries Ltd.</li>


</ul>
</div>
<div className={classes.dow}>
<ul className={classes.eee}>
<li className={classes.dd}> Recon Oil Industries Pvt. Ltd.</li>
<li className={classes.dd}>  R.R.Oomerbhoy Pvt. Ltd. </li>
<li className={classes.dd}>Lotus Refineries Ltd. </li>



</ul>

</div>

</div>

</div>

    </div>
  )
}

export default OurClient